@import url(https://fonts.googleapis.com/css?family=Electrolize);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.header {
    display: flex;
    color: white;
    background-color: #320435;
    height: 70px;
    align-items: center;
    font-family: 'Electrolize', sans-serif;
    font-size: 20px;
    justify-content: space-between
}
.header span {
    margin-left:20px;
}
.contactInfo {
    font-size: 15px;
}
.image{
    background-image: url("/assets/Images/imageOne.jpg"); 
    height: 700px; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    color: white;
    font-family: 'Electrolize', sans-serif;
    display: flex;
    justify-content: center;
   
}

.image div {
    font-size: 50px;
    margin-top: 100px;
}
@media only screen and (max-width: 600px) {
    .image {
      height: 436px;
      background-size: 654px;
   
    }
    .image div {
        font-size: 50px;
        margin-top: 17px;
    }
  }
.aboutMe {
    height: 400px;
    background-color: #5D1942;
    padding:100px;
    padding-top:25px;
}
.title {
    font-family: 'Electrolize', sans-serif;
    color:white;
    font-size: 30px;
}

.myPicture{
    background-image: url("/assets/Images/al.jpg"); 
    height: 100px; 
    width: 100px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    color: white;
    font-family: 'Electrolize', sans-serif;
    display: flex;
    box-shadow: 3px 3px #320435;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    left: -25px;
}

.card {
    height:150px;
    width: 400px;
    background-color: whitesmoke;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-family: 'Electrolize', sans-serif;
    padding: 50px;
    position: relative;
    margin-top: 100px;
  }
  .contact {
    font-family: 'Electrolize', sans-serif;
    color: whitesmoke;
    display: flex;
    padding-left: 50px;
  }

  .filler {
    background-image: url("/assets/Images/screens.png"); 
    height: 400px; 
    width: 400px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    color: white;
    font-family: 'Electrolize', sans-serif;
    margin-top:20px;
  
   
  }
  .aboutMeContent {
      display: flex;
      justify-content: space-around;
  }
  .card p {
      text-align: left;
  }

  @media only screen and (max-width: 600px) {
    .aboutMe {
        padding: 40px;
        height: 500px;
    }
    .filler {
        display: none;
    }
    .card {
        height: 270px;
    }
  }
.skills {
    background-color: #942F41;
    height: 450px;
    font-family: 'Electrolize', sans-serif;
    padding:100px;
    padding-top:30px;
    
}
.title {
    color: white;
    font-family: 'Electrolize', sans-serif;
    font-size: 30px;
   
}

.content {
    display: flex;
    flex-wrap: wrap;
    padding: 100px;
    padding-top: 60px;
}
.skills .content div {
   
    margin: 20px;
}
.content i {
    font-size: 100px;
    margin: 20px;
    color: white;
 
}
.skillsContainer {
    display: flex;

}

@media only screen and (max-width: 600px) {
    .content{
        flex-direction: column;
        padding: 0px;
        height: 900px;
    }
    .skills {
        padding: 0px;
        height: 100%;
        padding-top: 20px;
    }
    .skillsContainer {
        flex-direction: column;
        margin: 0px;
        padding: 0px;
    }
    .skills .content div {
   
        margin: 0px;
        margin-bottom: 50px;
    }
  }
.myWork {
    background-color: #C74741;
    height: 850px;
    font-family: 'Electrolize', sans-serif;
    padding: 100px;
    padding-top: 20px;
}
.description {
    font-family: 'Electrolize', sans-serif;
    color:white;
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    text-align: left;
   
}
.description div {
    width: 400px;
    margin: 5px;
}
.workContainer {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    margin-top:40px;
}

.workCard {
    height: 200px; 
    width: 500px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    margin: 10px;
}

@media only screen and (max-width: 600px) {
    .myWork {
        padding: 0px;
        height: 100%;
        padding-bottom:30px;
        padding-top: 30px;
   
    }
    .workCard {
        width: 262px;
        margin: 5px;
        height: 83px;
        background-size: 361px;
    }
    .description div {
        width: 280px;
        margin: 0px;
        margin-bottom: 10px;
    }
}


.contact {
    background-color: #5D1942;
    height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 2px;
    text-align: left;
}
.contact div {
    margin: 3px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

