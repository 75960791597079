.image{
    background-image: url("/assets/Images/imageOne.jpg"); 
    height: 700px; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    color: white;
    font-family: 'Electrolize', sans-serif;
    display: flex;
    justify-content: center;
   
}

.image div {
    font-size: 50px;
    margin-top: 100px;
}
@media only screen and (max-width: 600px) {
    .image {
      height: 436px;
      background-size: 654px;
   
    }
    .image div {
        font-size: 50px;
        margin-top: 17px;
    }
  }