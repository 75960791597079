

.header {
    display: flex;
    color: white;
    background-color: #320435;
    height: 70px;
    align-items: center;
    font-family: 'Electrolize', sans-serif;
    font-size: 20px;
    justify-content: space-between
}
.header span {
    margin-left:20px;
}
.contactInfo {
    font-size: 15px;
}